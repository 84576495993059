(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@aws-sdk/client-s3"), require("cos-js-sdk-v5"));
	else if(typeof define === 'function' && define.amd)
		define(["@aws-sdk/client-s3", "cos-js-sdk-v5"], factory);
	else if(typeof exports === 'object')
		exports["lscos-client"] = factory(require("@aws-sdk/client-s3"), require("cos-js-sdk-v5"));
	else
		root["lscos-client"] = factory(root["@aws-sdk/client-s3"], root["cos-js-sdk-v5"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__70__, __WEBPACK_EXTERNAL_MODULE__677__) => {
return 